import { type PhonelineSetupInformation } from '~/scopes/checkout/stores/bouyguesStore'

export const MOBILE_PLAN_ERROR = {
  NO_ACCESS_TOKEN: 'No access token set',
  NO_PHONELINE_SETUP_INFORMATION: 'No phoneline setup information set',
}

export function getMobilePlanCustomerInformation(
  hasMobilePlan: boolean,
  accessToken: string | null,
  phonelineSetupInformation: PhonelineSetupInformation | null,
) {
  if (!hasMobilePlan) {
    return null
  }

  if (!accessToken) throw new Error(MOBILE_PLAN_ERROR.NO_ACCESS_TOKEN)
  if (!phonelineSetupInformation)
    throw new Error(MOBILE_PLAN_ERROR.NO_PHONELINE_SETUP_INFORMATION)

  return {
    customerInformation: {
      accessToken,
      iban: phonelineSetupInformation.iban,
      childrenProtection: phonelineSetupInformation.childrenProtection,
      offerOption: phonelineSetupInformation.offerOption,
      phoneNumber: phonelineSetupInformation.phoneNumber,
      rioCode: phonelineSetupInformation.rioCode,
    },
  }
}
