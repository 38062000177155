import type { LooseAutocomplete } from '@backmarket/utils/types/LooseAutocomplete'

import { PaymentSubmitError } from './PaymentSubmitError'

/**
 * By default an error thrown inside the `create-payment` function will be handled
 * and a default error message will be passed to the `submit-error` event.
 *
 * If you want to react on specific errors differently, you can throw a `CreatePaymentError` instead.
 * This object allows you to pass a custom `readableMessage` or `redirection` that will be then sent to the `submit-error` event.
 */
export class CreatePaymentError extends PaymentSubmitError {
  constructor({
    scope,
    ...args
  }: Omit<ConstructorParameters<typeof PaymentSubmitError>[0], 'source'> & {
    scope: LooseAutocomplete<'checkout' | 'buyback'>
  }) {
    super({ ...args, source: scope })
  }
}
