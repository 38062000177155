export default {
  subtitle: {
    id: 'discount_input_subtitle',
    defaultMessage: 'To you: a discount and the good life!',
  },
  placeholder: {
    id: 'discount_input_placeholder',
    defaultMessage: 'Enter your promo code',
  },
  checkCode: {
    id: 'discount_input_check_code',
    defaultMessage: 'Check this code',
  },
}
