<template>
  <RevCard class="mb-20 p-24">
    <span class="body-1">
      {{ i18n(translations.subtitle) }}
    </span>

    <form @submit.prevent="handleSubmit">
      <div class="mt-16 flex flex-col md:flex-row">
        <RevInputText
          id="discount"
          v-model="currentToken"
          class="h-full w-full"
          :disabled
          :error
          :icon="IconGift"
          :label="i18n(translations.placeholder)"
          :maxlength="128"
          @input="handleTokenInput"
        />

        <RevButton
          class="mt-12 h-full md:ml-24 md:mt-0"
          data-qa="submit-button"
          data-test="submit-button"
          :disabled="isSubmitButtonDisabled"
          type="submit"
          variant="secondary"
        >
          {{ i18n(translations.checkCode) }}
        </RevButton>
      </div>
    </form>
  </RevCard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevInputText } from '@ds/components/InputText'
import { IconGift } from '@ds/icons/IconGift'

import translations from './DiscountInput.translations'

const i18n = useI18n()
const tracking = useTracking()

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    error?: string
    token?: string
  }>(),
  {
    disabled: false,
    error: '',
    token: '',
  },
)

const emit = defineEmits<{
  check: [string]
  change: [string]
}>()

const currentToken = ref(props.token)

const isSubmitButtonDisabled = computed(
  () => props.disabled || isEmpty(currentToken.value.trim()),
)

const handleSubmit = () => {
  tracking.trackClick({ zone: 'promoCode', name: 'verifyCode' })
  emit('check', props.token)
}

const handleTokenInput = (event: Event) => {
  const { value } = event.target as HTMLInputElement

  emit('change', value)
}
</script>
