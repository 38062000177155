<template>
  <div>
    <div v-if="discountStore.isApplied">
      <RevLink
        v-if="formattedDeduction"
        :class="[
          'text-static-success-hi text-left no-underline',
          { ['pointer-events-none line-through']: discountStore.isDisabled },
        ]"
        data-qa="discount-status"
        @click="handleAppliedDeductionClick"
      >
        <p class="body-1-bold">
          {{
            i18n(translations.appliedDiscount, { amount: formattedDeduction })
          }}
        </p>
      </RevLink>

      <p
        v-if="discountStore.isDisabled"
        class="text-static-danger-hi body-2 my-12"
      >
        {{ discountStore.errorMessage }}
      </p>
    </div>

    <DiscountInput
      v-else
      data-qa="discount-input"
      data-test="discount-input"
      :disabled="isInputDisabled"
      :error="discountStore.errorMessage"
      :token="discountStore.token"
      @change="handleInputChange"
      @check="handleInputCheck"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import { useDiscountStore } from '../../stores/discountStore'

import translations from './DiscountCard.translations'
import DiscountInput from './DiscountInput.vue'

const isCheckingToken = ref(false)
const isSettingDeduction = ref(false)

const discountStore = useDiscountStore()
const i18n = useI18n()
const tracking = useTracking()

const isInputDisabled = computed(
  () =>
    isCheckingToken.value ||
    isSettingDeduction.value ||
    discountStore.isDisabled,
)

const formattedDeduction = computed(() =>
  i18n.price(discountStore.discount.deduction),
)

const handleAppliedDeductionClick = () => {
  // Do not allow resetting when disabled, as user wouldn't be able to apply
  // it back (albeit disabled), and get frustrated.
  if (discountStore.isDisabled) {
    return
  }
  try {
    discountStore.reset()
  } catch {
    // Fails silently
  }
}

const handleInputChange = (token: string) => {
  discountStore.saveToken(token)
}

const handleInputCheck = async (token: string) => {
  isCheckingToken.value = true
  try {
    await discountStore.checkToken(token)
    tracking.trackClick({
      name: discountStore.errorMessage ? 'fail' : 'success',
      zone: 'verifiyCode',
    })
  } catch {
    // Fails silently
  }
  isCheckingToken.value = false
}
</script>
