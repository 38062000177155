export default {
  title: {
    id: 'funnel_payment_page_payment_title',
    defaultMessage: 'Confirm and pay',
  },
  loadingPayment: {
    id: 'funnel_payment_page_loading_payment',
    defaultMessage: 'Loading secured payment',
  },
}
