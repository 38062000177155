import { HttpApiError } from '@backmarket/http-api'
import { CreatePaymentError } from '@backmarket/nuxt-layer-payment/form'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import { CHECKOUT } from '../routes-names'

import translations from './usePaymentErrors.translations'

export const CHECKOUT_ERRORS_TYPE = {
  NO_STOCK: '/errors/payment/creation/no-stock',
  OFFLINE_INSURANCE: '/errors/payment/creation/offline-insurance-offer',
} as const

type CheckoutPaymentErrorType = {
  [key: string]: {
    redirect: string
    title: I18nDefinition
    content: I18nDefinition
  }
}

const ERROR_TYPES: CheckoutPaymentErrorType = {
  [CHECKOUT_ERRORS_TYPE.OFFLINE_INSURANCE]: {
    redirect: CHECKOUT.CART,
    title: translations.insuranceOfflineTitle,
    content: translations.insuranceOfflineContent,
  },
  [CHECKOUT_ERRORS_TYPE.NO_STOCK]: {
    redirect: CHECKOUT.CART,
    title: translations.noStockTitle,
    content: translations.noStockDescription,
  },
}

export default function usePaymentErrors() {
  const i18n = useI18n()
  const { openErrorToast } = useTheToast()

  const handlePaymentErrors = (error: HttpApiError) => {
    const errorType = ERROR_TYPES[error?.type]

    if (errorType && errorType.redirect) {
      openErrorToast({
        title: i18n(errorType.title),
        content: i18n(errorType.content),
      })

      throw new CreatePaymentError({
        scope: 'checkout',
        cause: error,
        message: error.message,
        type: error.type,
        redirection: {
          method: 'GET',
          link: {
            type: 'internal',
            name: errorType.redirect,
          },
        },
      })
    }

    // Remove once translations are done
    if (error.type === '/errors/payment/creation/no-stock') {
      throw new CreatePaymentError({
        scope: 'checkout',
        cause: error,
        message: error.message,
        type: error.type,
        redirection: {
          method: 'GET',
          link: {
            type: 'internal',
            name: CHECKOUT.CART,
          },
        },
      })
    }

    throw error
  }

  return {
    handlePaymentErrors,
  }
}
